import { AdminDesignTemplateSpec } from "@core/models/admin-design-templates";
import { CreativeSpec } from "@core/models/creative.types";

import { Observable, Subject } from "rxjs";
import { Socket, io } from "socket.io-client";
import * as uuid from "uuid";

import { environment } from "../../../../environments/environment";

export interface ImageSize {
  width: number;
  height: number;
}
export interface ImageResponse {
  image: ArrayBuffer;
  sizes: {
    target_size: ImageSize;
    resized_size: ImageSize;
    final_size: ImageSize;
  };
  position: any;
}

export interface ImageSpec {
  hash: string;
  css: string;
  response: ImageResponse;
}

export type PreviewImagesResponse = ArrayBuffer[];

export interface SocketFuture<T> {
  type: "progress" | "error" | "result";
  progress?: number;
  error?: { msg: string; info: any };
  result?: T;
}

export interface SocketProgress {
  id: string;
  progress: number;
}

export interface SocketConfig {
  company: number;
  account: number;
  proctor?: number;
  session?: string;
}

export class CreativesSocketService {
  socket: Socket;

  private _onConnect = new Subject<any>();
  private _onDisconnect = new Subject<any>();

  onConnect = this._onConnect.asObservable();
  onDisconnect = this._onDisconnect.asObservable();

  authConfig?: SocketConfig;

  subjects: Record<string, Subject<SocketFuture<any>>> = {};

  constructor(authConfig: SocketConfig) {
    this.authConfig = authConfig;
    const tk = localStorage["auth_bearer"];
    this.setup(tk != null ? JSON.parse(tk)["access_token"] : null);
  }

  setup(token?: string) {
    let headers = {};

    if (this.authConfig) {
      headers = {
        AUTHORIZATION: token,
        DEBUG: environment.SocketDebug,
        ACCOUNT: this.authConfig.account,
        COMPANY: this.authConfig.company,
        PROCTOR: this.authConfig.proctor,
        SESSION: this.authConfig.session,
      };
    } else {
      headers = {
        DEBUG: environment.SocketDebug,
        ANON: "true",
      };
    }

    this.socket = io(environment.SocketEndpoint, {
      withCredentials: true,
      transportOptions: {
        polling: {
          extraHeaders: headers,
        },
      },
    });

    this.socket.on("connect", () => {
      this._onConnect.next(true);
    });

    this.socket.on("disconnect", (reason) => {
      this._onDisconnect.next(reason);
    });

    this.socket.on("error", (_error) => {});

    this.socket.on("progress", (data: SocketProgress) => {
      // Emit on correct subject
      const s = this.subjects[data.id];
      s?.next({ type: "progress", progress: data.progress });
    });

    this.socket.connect();
  }

  private newProgressTask<T>(
    event: string,
    data: any,
    resultMapper?: (result: any) => T,
  ): Observable<SocketFuture<T>> {
    const id = uuid.v4();
    const subject = new Subject<any>();
    this.subjects[id] = subject;

    data["id"] = id;

    this.socket.emit(event, data, (success, msg, data: any | T) => {
      if (!success) {
        subject.error({ type: "error", error: { msg: msg, info: data } });
        subject.complete();
        this.subjects[id] = null;
        return;
      }

      const result: T = resultMapper ? resultMapper(data) : data;
      subject.next({
        type: "result",
        result: result,
      });
      subject.complete();

      // Clean subject
      this.subjects[id] = null;
    });

    return subject.asObservable();
  }

  destroy() {
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }

  renderLayer(
    spec: CreativeSpec,
    layers: string[],
    resolution: number[] = [1080, 1080],
    filters: any = {},
    productOffset: number = 0,
    fakeProducts: boolean = false,
    v2 = true,
  ): Observable<{ number: ImageResponse }> {
    return new Observable((observer) => {
      this.socket.emit(
        "render",
        {
          spec: spec,
          layers: layers,
          resolution: resolution,
          filters: filters,
          productOffset: productOffset,
          fakeProducts: fakeProducts,
          v2: v2,
        },
        (success, msg, data) => {
          if (!success) {
            observer.error({ msg: msg, info: data });
            observer.complete();
            return;
          }

          observer.next(data);
          observer.complete();
        },
      );
    });
  }

  renderSingleImage(
    spec: CreativeSpec,
    resolution: number[] = [1080, 1080],
    filters: any = {},
    productOffset: number = 0,
    fakeProducts: boolean = false,
  ): Observable<{ image: ArrayBuffer }> {
    return new Observable((observer) => {
      this.socket.emit(
        "single_image",
        {
          spec: spec,
          resolution: resolution,
          filters: filters,
          productOffset: productOffset,
          fakeProducts: fakeProducts,
        },
        (success, msg, data) => {
          if (!success) {
            observer.error({ msg: msg, info: data });
            observer.complete();
            return;
          }

          observer.next(data);
          observer.complete();
        },
      );
    });
  }

  resetSession(): Observable<any> {
    return new Observable((observer) => {
      this.socket.emit("reset_session", {}, (_success, _msg, _data) => {
        observer.next(true);
        observer.complete();
      });
    });
  }

  renderManyImages(
    spec: CreativeSpec,
    resolution: [number, number] = [1080, 1080],
    filters: any = {},
    page: number = 1,
  ): Observable<SocketFuture<PreviewImagesResponse>> {
    const d = {
      spec: spec,
      resolution: resolution,
      filters: filters,
      page: page,
    };
    return this.newProgressTask("preview_many_images", d);
  }

  renderVideo(
    spec: CreativeSpec,
    resolution: [number, number] = [1080, 1080],
    filters: any = {},
  ): Observable<SocketFuture<string>> {
    const d = {
      spec: spec,
      resolution: resolution,
      filters: filters,
    };
    return this.newProgressTask("preview_video", d, (result) => result);
  }

  renderEffectVideo(
    effect_spec: {
      effect?: any;
      effect_in?: any;
      effect_out?: any;
    },
    resolution: [number, number] = [1080, 1080],
  ): Observable<SocketFuture<string>> {
    const d = {
      effect_spec: effect_spec,
      resolution: resolution,
    };
    return this.newProgressTask("preview_effect_video", d, (result) => result);
  }

  // renderFastlane(
  //   payload: FastlaneInput
  // ): Observable<SocketFuture<FastlaneResponse>> {
  //   return this.newProgressTask(
  //     "render_fastlane",
  //     { payload: payload },
  //     (data: FastlaneResponse) => {
  //       data.output = data.output.map((r) => {
  //         switch (r.type) {
  //           case "file":
  //             const s = r.data as string;
  //             r.data = `${environment.SocketEndpoint}/get-file?id=${s}`;
  //             break;
  //           case "image":
  //             const data = r.data as ArrayBuffer;
  //             r.data = arrayToDataString(data, `image/${r.ext}`);
  //             r.raw = data;
  //             break;
  //         }
  //         return r;
  //       });
  //       return data;
  //     }
  //   );
  // }

  // renderFastlaneAnon(uuid: string): Observable<SocketFuture<FastlaneResponse>> {
  //   return this.newProgressTask(
  //     "render_fastlane_shared",
  //     { uuid: uuid },
  //     (data: FastlaneResponse) => {
  //       data.output = data.output.map((r) => {
  //         switch (r.type) {
  //           case "file":
  //             const s = r.data as string;
  //             r.data = `${environment.SocketEndpoint}/get-file?id=${s}`;
  //             break;
  //           case "image":
  //             const data = r.data as ArrayBuffer;
  //             r.data = arrayToDataString(data, `image/${r.ext}`);
  //             r.raw = data;
  //             break;
  //         }
  //         return r;
  //       });
  //       return data;
  //     }
  //   );
  // }

  previewStyleGuidelineTemplate(
    styleGuidelineID: number,
    designTemplate: AdminDesignTemplateSpec,
    resolution: [number, number],
  ): Observable<ArrayBuffer> {
    // Private / internal admin way of previewing a single design template
    const d = {
      style_guide_id: styleGuidelineID,
      design_template: designTemplate,
      resolution: resolution,
    };
    return new Observable((observer) => {
      this.socket.emit(
        "preview_style_guideline_template",
        d,
        (success, msg, data) => {
          if (!success) {
            observer.error({ msg: msg, info: data });
            observer.complete();
            return;
          }
          observer.next(data);
          observer.complete();
        },
      );
    });
  }

  previewStyleGuideline(styleGuidelineID: number): Observable<ArrayBuffer> {
    // For regular users to preview their style guide
    const d = {
      style_guide_id: styleGuidelineID,
    };
    return new Observable((observer) => {
      this.socket.emit("preview_style_guideline", d, (success, msg, data) => {
        if (!success) {
          observer.error({ msg: msg, info: data });
          observer.complete();
          return;
        }
        observer.next(data);
        observer.complete();
      });
    });
  }
}
